import React from 'react';

import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { Stack, Typography } from '@mui/material';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ParalaxTitle from '../components/ParalaxTitle';
import imgBackground from '../images/fondo1.jpeg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/main.css';

function IndexPage() {
  return (
    <Layout>
      <SEO title="Acerca de mi" />
      <section id="about">
        <ParalaxTitle text="Acerca de mi" imgBackground={imgBackground} />
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-8 my-auto pb-2">
              <StaticImage
                src="../images/artist.jpg"
                alt="S.P. Balcarcel: artista guatemalteca, pintora de arte comtemporanea."
              />
            </div>
            <div className="col-lg-6 col-md-8 my-auto" style={{ textAlign: 'justify' }}>
              <Stack spacing={2}>
                <Typography variant="h3" className="text-center">
                  Biografía
                </Typography>
                <Typography>
                  S.P. Balcarcel nació en Guatemala, su descubrimiento del arte empieza a muy
                  temprana edad. A la edad de diecisiete años comienza su carrera artística
                  realizando obras caricaturescas e ilustrativas, en el proceso de aprendizaje, su
                  arte fue evolucionando hasta conseguir una representación más realista de sus
                  obras. Siendo autodidacta hasta esa época; poco después ingresa a la escuela
                  municipal de bellas artes de la ciudad de Guatemala.
                </Typography>
                <Typography>
                  Su arte fue evolucionado hasta llegar a obras de arte fantástico, que es su
                  enfoque principal. Con una gran afinidad por la lectura, los libros siempre fueron
                  una fuente de inspiración para realizar sus obras, su interés por la ilustración y
                  la lectura, crearon en ella un gran deseo por escribir, allí nace Dulcinea el
                  primer libro de los cuentos de Colnemar.
                </Typography>
                <Typography>
                  Entre sus más reconocidas obras se encuentra la serie &quot;Gigante Azul&quot;, la
                  obra &quot;Espíritu de Libertad&quot; que es una representación de la cultura de
                  su país, y la serie de obras abstractas con título de &quot;Mantenimiento&quot;.
                </Typography>
              </Stack>
            </div>
            <div className="col-lg-3 col-md-10 my-auto">
              <Typography variant="h5" mb={1} className="text-center">
                Exposiciones
              </Typography>
              <ul className="list-group">
                <li className="list-group-item">
                  <Typography>
                    Concurso fotográfico de Chirmol City 2015 “CIUDAD CULTURA”, Obra paisaje urbano.
                  </Typography>
                </li>
                <li className="list-group-item">
                  <Typography>
                    XVIII Certamen de Pintura Dr.Gerardo Barreno Anleu, Obra Espiritu de libertad.
                  </Typography>
                </li>
                <li className="list-group-item">
                  <Typography>
                    Exposición colectiva “68 RETRATOS Y 28 ESCULTURAS, Serie Mantenimiento.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
}

export default IndexPage;
